<template>
    <div class="centered-form">
        <div class="mainContainer">
            <div class="form-group">
                <img class="mt-3" src="@/assets/images/logo/newPassword.png" alt="success-page"
                    style="max-width: 200px; height: 250px;" />
            </div>
            <div class="form-section" style="margin-left: auto;">
                <h5>Reset Your Password</h5>
                <p class="password-rules" style="width: 280px; font-size: 10px;">
                    Password must meet the following requirements: 
                <ul style="font-size:10px;">
                    <li>At least 8 characters long</li>
                    <li>At least 1 lowercase letter</li>
                    <li>At least 1 uppercase letter</li>
                    <li>At least 1 number</li>
                    <li>At least 1 symbol</li>
                </ul>
                </p>
                <validation-observer ref="loginValidation" #default="{ invalid }">
                    <b-form class="auth-login-form form-style mt-2" @submit.prevent>
                        <b-form-group class="form-forgot">
                            <validation-provider #default="{ errors }" name="Password" rules="required">
                                <label for="username" class="username">Enter New Password</label>
                                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                                    <b-form-input id="password" v-model="password" :type="passwordFieldType"
                                        class="form-control-merge" :state="errors.length > 0 ? false : null"
                                        name="login-password" placeholder="Password" />

                                    <b-input-group-append is-text>
                                        <feather-icon class="cursor-pointer" :icon="passwordToggleIcon"
                                            @click="togglePasswordVisibility" />
                                    </b-input-group-append>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-form-group class="form-forgot">
                            <validation-provider #default="{ errors }" name="ConfirmPassword">
                                <label for="username" class="username">Confirm New Password</label>
                                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                                    <b-form-input id="confirmPassword" v-model="confirmPassword" class="form-control-merge"
                                        :state="errors.length > 0 ? false : null" name="confirm-password"
                                        placeholder="Confirm Password" />
                                    <!-- <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append> -->
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-button @click="validateForm" variant="primary" type="submit">
                            Update Password
                        </b-button>
                    </b-form>
                </validation-observer>
            </div>
        </div>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
} from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useJwt from "@/auth/jwt/useJwt";
import axios from "axios";
import { getUserToken } from "@/auth/utils";

export default {
    components: {
        // BSV
        BButton,
        BForm,
        BFormInput,
        BFormGroup,
        BCard,
        BCardTitle,
        BLink,
        VuexyLogo,
        BCardText,
        BInputGroup,
        BInputGroupAppend,
        BFormCheckbox,
        ValidationProvider,
        ValidationObserver,
        ToastificationContent,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            password: "",
            confirmPassword: "",
            // validation rules
            required,
        };
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
        },
    },
    // mounted() {
    //     this.checkIfValidLink()
    // },
    methods: {
        checkPasswordMatch() {
            return (
                this.password.trim() != "" &&
                this.confirmPassword.trim() != "" &&
                this.password === this.confirmPassword
            );
        },
        showToast(title, text, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title,
                    icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
                    text,
                    variant,
                },
            });
        },
        validateForm() {
            if (!this.$route.params.accessToken) {
                this.showToast("Error", "Missing access token.", "danger");
                return;
            }
            if (this.checkPasswordCriteria()) {
                if (this.checkPasswordMatch()) {
                    axios
                        .post(
                            `${process.env.VUE_APP_SERVER_URL}/api/admin/reset-password/`,
                            {
                                resetToken: this.$route.params.accessToken,
                                password: this.password,
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${getUserToken()}`,
                                    "Content-type": "application/json",
                                },
                            }
                        )
                        .then((response) => {
                            this.showToast(
                                "Success",
                                "Password Changed Successfully!.",
                                "success"
                            );
                            return this.$router.push("/login");
                        })
                        .catch((error) => {
                            console.log(error);
                            this.showToast("Error", error.response.data.error, "danger");
                        });
                } else {
                    this.showToast(
                        "Error",
                        "Both of the passwords does not match.",
                        "danger"
                    );
                }
            }
        },
        redirectToLogin() {
            return this.$router.push("/login");
        },
        checkPasswordCriteria() {
            this.errors = [];
            var password = this.password;
            const criteria = {
                minLength: 8,
                minLowercase: 1,
                minUppercase: 1,
                minNumbers: 1,
                minSymbols: 1,
            };

            const lowercaseRegex = /[a-z]/;
            const uppercaseRegex = /[A-Z]/;
            const numbersRegex = /[0-9]/;
            const symbolsRegex = /[^a-zA-Z0-9]/;

            if (password.length < criteria.minLength) {
                this.showToast("Error", "Minimum 8 characters are required.", "danger");
                return false;
            }

            if (!lowercaseRegex.test(password)) {
                this.showToast(
                    "Error",
                    "Minimum 1 lower case character is required.",
                    "danger"
                );
                return false;
            }

            if (!uppercaseRegex.test(password)) {
                this.showToast(
                    "Error",
                    "Minimum 1 upper case character is required.",
                    "danger"
                );

                return false;
            }

            if (!numbersRegex.test(password)) {
                this.showToast("Error", "Minimum 1 number is required.", "danger");
                return false;
            }

            if (!symbolsRegex.test(password)) {
                this.showToast(
                    "Error",
                    "Minimum 1 symbol is required (/, !, @, #, $, %)",
                    "danger"
                );
                return false;
            }

            return true;
        },
        googleLogin() {
            axios
                .get(`${process.env.VUE_APP_SERVER_URL}/api/admin/login/google/init`)
                .then((response) => {
                    const url = response.data;
                    window.location.replace(url);
                })
                .catch((error) => {
                    this.showToast(
                        "Error",
                        "Couldn't process the request at the moment. Please try again later!",
                        "danger"
                    );
                });
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.with-background {
    background-image: url("../../assets/images/logo/backgroundImage.svg");
    background-size: cover;
}

.centered-form {
    display: flex;
    background-image: url("../../assets/images/logo/backgroundImage.svg");
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.form-group {
    margin-bottom: 10px;
    width: 45%;
}

label {
    display: block;
    font-weight: bold;
}

.form-control-merge {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
}

input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
}

button {
    background-color: #6a00bf;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.form-section {
    margin-left: 50px !important;
}

button:hover {
    background-color: #57049b;
}

.mainContainer {
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
    padding: 40px;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.username {
    font-size: 10px;
}

.form-style {
    width: 600px !important;
}
.form-forgot {
        justify-content: center;
        align-items: center;
        width: 40% !important;
    }

.text-content {}

@media only screen and (max-width: 767px) {
    .centered-form {
        display: grid;
        grid-template-columns: auto;
        background-image: url("../../assets/images/logo/backgroundImage.svg");
        background-size: cover;
        height: 150vh;
    }

    .mainContainer {
        display: grid;
        grid-template-columns: auto;
        width: 100%;
        height: auto;
    }

    .form-style {
        width: 200px !important;
    }

    .form-forgot {
        justify-content: center;
        align-items: center;
        width: 100% !important;
    }

    .form-section {
        margin-left: 0px !important;
    }
}

@media only screen and (min-width: 767px) and (max-width: 1281px) {
    .centered-form {
        display: grid;
        width: 500px;
        grid-template-columns: auto;
        background-image: url("../../assets/images/logo/backgroundImage.svg");
        background-size: cover;
        height: 150vh;
    }

    .mainContainer {
        display: grid;
        grid-template-columns: auto;
        width: 100%;
        height: auto;
    }

    .form-style {
        width: 300px !important;
    }

    .form-forgot {
        justify-content: center;
        align-items: center;
        width: 100% !important;
    }

    .form-section {
        margin-left: 0px !important;
    }
}
@media (min-width: 1024px) and (max-width: 1500px) {
    .centered-form {
        display: grid;
        width: 500px;
        grid-template-columns: auto;
        background-image: url("../../assets/images/logo/backgroundImage.svg");
        background-size: cover;
        height: 150vh;
    }

    .mainContainer {
        display: grid;
        grid-template-columns: auto;
        width: 100%;
        height: auto;
    }

    .form-style {
        width: 300px !important;
    }

    .form-forgot {
        justify-content: center;
        align-items: center;
        width: 100% !important;
    }

    .form-section {
        margin-left: 0px !important;
    }

}
</style>
